<template>
<div class="scan" style="padding-top:80px">
    <div v-bind:class="[ pagemode==SCAN_PRINT_MODE  ? 'print' : '' ]" style="padding-bottom:80px">
        <a-layout>
            <a-layout-content>
                <a-row class="s-layout" style="overflow-x:scroll">
                    <a-col :span="24">

                        <a-row>
                            <a-col :span="24" style="text-align:center" v-if="pagemode==SCAN_NORMAL_MODE">
                                <span class="s-scan-font1">스토어링크 상위노출 분석으로<br />키워드 상위노출에 필요한 지표과 마케팅 비용을 확인 해 보세요</span>
                                <div style="width:70%;margin: 0 auto;margin-top:30px" class="s-scan-btn1">
                                    <a-input-search v-model="keywordName" placeholder="키워드를 입력해주세요" @search="invokeSearch">
                                        <a-button slot="enterButton" style="color:white" :disabled="!keywordName">
                                            분석하기
                                        </a-button>
                                    </a-input-search>
                                </div>
                            </a-col>
                            <a-col :span="24" style="margin-top:80px;margin-bottom:20px" v-if="mode==1">
                                <div class="s-scan-font2" style="margin-bottom:20px">쇼핑몰 선택</div>

                                <div class="s-scan-card5" style="padding-top:45px">
                                    <a-radio-group v-model="mallType" @change="onChangeMallType">
                                        <a-radio :value="ShoppingMall.NAVER_STORE">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-navershopping-copy%402x.png?alt=media&token=5d4c708b-5e20-4c48-9b00-2bd14130e4ad" style="height:20px" />
                                        </a-radio>
                                        <a-radio :value="ShoppingMall.COUPANG">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-coupang-copy%402x.png?alt=media&token=2cd024fe-02c8-4ddf-a767-8fa0bb5035c1" style="height:20px" />
                                        </a-radio>
                                        <a-radio :value="ShoppingMall.TMON">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-tmon-copy%402x.png?alt=media&token=40e3bd2e-4151-4e0f-be70-397aabb6d3cc" style="height:20px" />
                                        </a-radio>
                                        <a-radio :value="ShoppingMall.WEMAFE">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-wemakeprice%402x.png?alt=media&token=c6be4285-42c6-4d36-9351-734c393c5b7c" style="height:20px" />
                                        </a-radio>
                                        <a-radio :value="ShoppingMall.KAKAO">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-kakaoshopping%402x.png?alt=media&token=b65c4a3a-2435-4b57-877d-08eb7b2c3528" style="height:20px" />
                                        </a-radio>
                                    </a-radio-group>
                                </div>
                            </a-col>
                            <a-col :span="14" style="margin-top:57px;margin-bottom:20px" v-if="mode==1">
                                <div class="s-scan-font2"><span class="s-scan-tag" v-if="displayKeyword">{{displayKeyword}}</span> &nbsp;&nbsp;상위노출분석</div>
                                <div class="s-scan-font3" style="margin-top:10px;margin-bottom:20px"> {{displayKeyword}} 키워드에 대한 상위노출 분석 내용입니다.</div>
                                <div class="s-scan-font4">해당 제품이 <span style="color:#000000">1page에 노출되기 위해 필요한 구매 수와 마케팅 비용</span>입니다.</div>

                            </a-col>
                            <a-col :span="14" style="margin-top:57px;margin-bottom:20px" v-if="mode==2">
                                <div class="s-scan-font2">순위별 제품분석</div>
                                <div class="s-scan-font3" style="margin-top:10px;margin-bottom:20px"> *해당 순위는 네이버 스마트스토어 대한 순위별 판매수량입니다. 타 쇼핑몰은 해당 되지 않습니다.</div>

                            </a-col>
                            <a-col :span="10" style="margin-top:57px;margin-bottom:20px">
                                <div style="display:flex;position:absolute;right:0;">
                                    <!-- <a-button  style="margin-right:10px;width:200px">
                                    전체화면보기 (프린트용)
                                </a-button> -->
                                    <a-button style="width:250px;height:40px" @click="mode=2" v-if="mode==1 && printme">
                                        1페이지 상세 분석하기
                                    </a-button>
                                    <a-button style="width:250px;height:40px" @click="mode=1" v-if="mode==2 && printme">
                                        분석내용 돌아가기
                                    </a-button>
                                    <!-- <a-button @click="onGotoPrintPage()" :disabled="!keywordName" v-if="pagemode==SCAN_NORMAL_MODE" style="width:250px;margin-left:10px">
                                        프린트 페이지 이동
                                    </a-button> -->
                                    <a-button @click="download()" v-if="pagemode==SCAN_PRINT_MODE && printme" style="width:200px;margin-left:10px">
                                        <i class="fal fa-print">&nbsp;&nbsp;</i>화면 프린트 하기
                                    </a-button>

                                </div>
                            </a-col>
                            <div v-if="mode==1">
                                <a-col :span="24" class="s-scan-card1">
                                    <a-row style="height:100%">
                                        <a-col :span="24" style="text-align:center" v-if="!avgPurchase40">
                                            <div class="empty"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                <span>데이타가 없습니다.</span></div>
                                        </a-col>
                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-purchase.png"><span>구매수</span>
                                            </div>
                                            <div class="f1">
                                                {{parseInt(obj40.purchase).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{parseInt(obj40.purchase).toLocaleString()}}개 X 5,000원
                                            </div>
                                            <div class="f3">
                                                {{getP1_40.toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>
                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-textreview.png"><span>텍스트 리뷰 수</span>
                                            </div>
                                            <div class="f1">
                                                {{ parseInt(obj40.textreview).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{ parseInt(obj40.textreview).toLocaleString()}}개 X 1,000원
                                            </div>
                                            <div class="f3">
                                                {{getP2_40.toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-photo.png"><span>포토 리뷰</span>
                                            </div>
                                            <div class="f1">
                                                {{ parseInt(obj40.photoreview).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{ parseInt(obj40.photoreview).toLocaleString()}}개 X 2,000원
                                            </div>
                                            <div class="f3">
                                                {{getP3_40.toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-heart.png"><span>찜</span>
                                            </div>
                                            <div class="f1">
                                                {{ parseInt(obj40.zzim).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{ parseInt(obj40.zzim).toLocaleString()}}개 X 1,000원
                                            </div>
                                            <div class="f3">
                                                {{getP4_40.toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-qna.png"><span>Q&A</span>
                                            </div>
                                            <div class="f1">
                                                {{parseInt(obj40.qa).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{parseInt(obj40.qa).toLocaleString()}}개 X 1,000원
                                            </div>
                                            <div class="f3">
                                                {{getP5_40.toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-schedule.png"><span>구매 진행 일정</span>
                                            </div>
                                            <div class="f4">
                                                1주차 - {{obj40.w1}}건 2주차 - {{obj40.w2}}건 <br />
                                                3주차 - {{obj40.w3}}건 4주차 - {{obj40.w4}}건 <br />
                                                5주차 - {{obj40.w5}}건
                                            </div>
                                            <div class="f5">
                                                <img src="@/assets/images/icon/icon-pay.png"><span>최종 결제금액 </span>
                                            </div>
                                            <div class="f6">
                                                {{getTotal40.toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-col>
                                <a-col :span="24" style="margin-top:30px;margin-bottom:20px">
                                    <div class="s-scan-font4">해당 제품이 <span style="color:#000000">1page 상위 10위 내에 노출되기 위해 필요한 구매 수와 마케팅 비용</span>입니다.</div>
                                </a-col>
                                <a-col :span="24" class="s-scan-card1">
                                    <a-row style="height:100%">
                                        <a-col :span="24" style="text-align:center" v-if="!avgPurchase10">
                                            <div class="empty"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                <span>데이타가 없습니다.</span></div>
                                        </a-col>
                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-purchase.png"><span>구매수</span>
                                            </div>
                                            <div class="f1">
                                                {{parseInt(obj10.purchase).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{parseInt(obj10.purchase).toLocaleString()}}개 X 5,000원
                                            </div>
                                            <div class="f3">
                                                {{(getP1_10).toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>
                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-textreview.png"><span>텍스트 리뷰 수</span>
                                            </div>
                                            <div class="f1">
                                                {{ parseInt(obj10.textreview).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{ parseInt(obj10.textreview).toLocaleString()}}개 X 1,000원
                                            </div>
                                            <div class="f3">
                                                {{(getP2_10).toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-photo.png"><span>포토 리뷰</span>
                                            </div>
                                            <div class="f1">
                                                {{ parseInt(obj10.photoreview).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{ parseInt(obj10.photoreview).toLocaleString()}}개 X 2,000원
                                            </div>
                                            <div class="f3">
                                                {{(getP3_10).toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-heart.png"><span>찜</span>
                                            </div>
                                            <div class="f1">
                                                {{ parseInt(obj10.zzim).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{ parseInt(obj10.zzim).toLocaleString()}}개 X 1,000원
                                            </div>
                                            <div class="f3">
                                                {{(getP4_10*2).toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-qna.png"><span>Q&A</span>
                                            </div>
                                            <div class="f1">
                                                {{parseInt(obj10.qa).toLocaleString()}}<span>개</span>
                                            </div>
                                            <div class="f2">
                                                {{parseInt(obj10.qa).toLocaleString()}}개 X 1,000원
                                            </div>
                                            <div class="f3">
                                                {{(getP5_10).toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-schedule.png"><span>구매 진행 일정</span>
                                            </div>
                                            <div class="f4">
                                                1주차 - {{obj10.w1}}건 2주차 - {{obj10.w2}}건 <br />
                                                3주차 - {{obj10.w3}}건 4주차 - {{obj10.w4}}건 <br />
                                                5주차 - {{obj10.w5}}건
                                            </div>
                                            <div class="f5">
                                                <img src="@/assets/images/icon/icon-pay.png"><span>최종 결제금액 </span>
                                            </div>
                                            <div class="f6">
                                                {{(getTotal10).toLocaleString()}} <span>원</span>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-col>
                                <a-col :span="24" style="margin-top:60px;">
                                    <div class="s-scan-font2"><span class="s-scan-tag" v-if="displayKeyword">{{displayKeyword}}</span> &nbsp;&nbsp;상위제품 분석</div>
                                    <div class="s-scan-font3" style="margin-top:10px;margin-bottom:0px"> {{displayKeyword}} 키워드에 대한 상위 제품들의 분석 내용입니다.</div>
                                </a-col>
                                <a-col :span="span3" style="margin-top:20px">
                                    <div class="s-scan-font4">1page 상위 10개 제품 1개월 평균 예측치</div>
                                    <a-row class="s-scan-card3" style="margin-top:20px" v-bind:class="[ span3==24  ? '' : 'right' ]">
                                        <a-co1 :span="24" style="text-align:center" v-if="!avgPurchase10">
                                            <div class="empty2"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                <span>데이타가 없습니다.</span></div>
                                        </a-co1>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-sell.png"><span>판매개수</span>
                                            </div>
                                            <div class="f1">
                                                {{avgPurchase10.toLocaleString()}}
                                            </div>
                                        </a-col>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-price.png"><span>평균가격</span>
                                            </div>
                                            <div class="f1">
                                                {{avgPrice10.toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1" v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-sales.png"><span>매출</span>
                                            </div>
                                            <div class="f1">
                                                {{(avgPurchase10*avgPrice10).toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                    </a-row>
                                </a-col>
                                <a-col :span="span3" style="margin-top:20px">
                                    <div class="s-scan-font4" v-bind:class="[ span3==24  ? '' : 'left' ]">1page 전체 제품 1개월 평균 예측치</div>
                                    <a-row class="s-scan-card3" style="margin-top:20px" v-bind:class="[ span3==24  ? '' : 'left' ]">
                                        <a-co1 :span="24" style="text-align:center" v-if="!avgPurchase40">
                                            <div class="empty2"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                <span>데이타가 없습니다.</span></div>
                                        </a-co1>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-sell.png"><span>판매개수</span>
                                            </div>
                                            <div class="f1">
                                                {{avgPurchase40.toLocaleString()}}
                                            </div>
                                        </a-col>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-price.png"><span>평균가격</span>
                                            </div>
                                            <div class="f1">
                                                {{avgPrice40.toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-sales.png"><span>매출</span>
                                            </div>
                                            <div class="f1">
                                                {{(avgPurchase40*avgPrice40).toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                    </a-row>
                                </a-col>

                                <a-col :span="24" style="margin-top:60px;">
                                    <div class="s-scan-font2"><span class="s-scan-tag" v-if="displayKeyword">{{displayKeyword}}</span> &nbsp;&nbsp;ROAS 분석</div>
                                    <div class="s-scan-font3" style="margin-top:10px;margin-bottom:0px"> {{displayKeyword}} 키워드의 상위노출에 대한 ROAS 분석입니다.</div>
                                </a-col>
                                <a-col :span="span3" style="margin-top:20px">
                                    <div class="s-scan-font4">1page 상위 10개 제품 1개월 평균 예측치</div>
                                    <a-row class="s-scan-card3" style="margin-top:20px" v-bind:class="[ span3==24  ? '' : 'right' ]">
                                        <a-col :span="24" style="text-align:center" v-if="!avgPurchase10">
                                            <div class="empty2"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                <span>데이타가 없습니다.</span></div>
                                        </a-col>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-sales.png"><span>1개월 예상매출</span>
                                            </div>
                                            <div class="f1">
                                                {{(avgPurchase10*avgPrice10).toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-pay.png"><span>필요 마케팅 비용</span>
                                            </div>
                                            <div class="f1">
                                                {{(getTotal10).toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1" v-if="avgPurchase10">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-roas.png"><span>ROAS</span>
                                            </div>
                                            <div class="f2">
                                                {{getROAS10}}<span>%</span>
                                            </div>
                                        </a-col>

                                    </a-row>
                                </a-col>
                                <a-col :span="span3" style="margin-top:20px">
                                    <div class="s-scan-font4" v-bind:class="[ span3==24  ? '' : 'left' ]">1page 전체 제품 1개월 평균 예측치</div>
                                    <a-row class="s-scan-card3" style="margin-top:20px" v-bind:class="[ span3==24  ? '' : 'left' ]">
                                        <a-col :span="24" style="text-align:center" v-if="!avgPurchase40">
                                            <div class="empty2"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                <span>데이타가 없습니다.</span></div>
                                        </a-col>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-sales.png"><span>1개월 예상매출</span>
                                            </div>
                                            <div class="f1">
                                                {{(avgPurchase40*avgPrice40).toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>
                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1 " v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-pay.png"><span>필요 마케팅 비용</span>
                                            </div>
                                            <div class="f1">
                                                {{getTotal40.toLocaleString()}}<span>원</span>
                                            </div>
                                        </a-col>

                                        <a-col :xs="24" :sm="24" :md="8" :lg="8 " :xl="8" class="s-scan-card3-1" v-if="avgPurchase40">
                                            <div class="f0">
                                                <img src="@/assets/images/icon/icon-roas.png"><span>ROAS</span>
                                            </div>
                                            <div class="f2">
                                                {{getROAS40}}<span>%</span>
                                            </div>
                                        </a-col>

                                    </a-row>
                                </a-col>
                            </div>
                            <div v-else>

                                <a-col :span="24">
                                    <a-row>
                                        <a-col :span="span1" v-for="item in storelist" :key="item.rank">
                                            <div class="s-scan-card4" style="margin-bottom:5px">
                                                <div><span class="s-scan-font5">#{{item.rank}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="s-scan-font6">1개월 평균 예측치</span></div>
                                                <div class="s-scan-font7 twoline">{{item.title}}</div>
                                                <div style="padding-top: 10px;display:flex">
                                                    <div class="s-scan-font8">판매개수</div>
                                                    <i class="far fa-minus s-scan-dash2"></i>
                                                    <div class="s-scan-font9" v-if="item.isGroup=='F'">{{parseInt(item.purchase).toLocaleString()}} <span class="s-scan-font10">개</span></div>
                                                    <div class="s-scan-font9" v-else style="margin-top:-5px"><a-tag color="blue">묶음</a-tag></div>

                                                </div>
                                                <div style="  padding-top: 10px;display:flex">
                                                    <div class="s-scan-font8">평균가격</div> <i class="far fa-minus s-scan-dash2"></i>
                                                    <div class="s-scan-font9">{{parseInt(item.price).toLocaleString()}}<span class="s-scan-font10">원</span></div>

                                                </div>
                                                <div style="  padding-top: 10px;display:flex">
                                                    <div class="s-scan-font8">매출</div> <i class="far fa-minus s-scan-dash2"></i>
                                                    <div class="s-scan-font9" v-if="item.isGroup=='F'">{{parseInt(item.purchase*item.price).toLocaleString()}}<span class="s-scan-font10">원</span></div>
                                                    <div class="s-scan-font9" v-else style="margin-top:-5px"><a-tag color="blue">묶음</a-tag></div>

                                                </div>

                                            </div>

                                        </a-col>

                                    </a-row>
                                </a-col>

                            </div>
                        </a-row>
                    </a-col>

                </a-row>
            </a-layout-content>
        </a-layout>
    </div>
</div>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

String.prototype.trims = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

import firebase from "firebase";
import {
  getCurrentTimeNew,
  getHex,
  dateDiffInDays,
  replaceAll,
  isMobile,
  SCAN_PRINT_MODE,
  SCAN_NORMAL_MODE,
  ShoppingMall
} from "../components/fnc.js";
const random = require("random");
export default {
  props: {
    pagemode: {
      type: String
    }
  },
  data() {
    return {
      ShoppingMall: ShoppingMall,
      ratio: 1,
      span1: 6,
      span2: 4,
      span3: 12,
      displayKeyword: "",
      mallType: ShoppingMall.NAVER_STORE,
      loading: false,
      keywordName: "",
      storelist: [],
      mode: 1,
      printme: true,
      dialogVisible: false,
      loadingbar: null,
      avgPrice10: 0,
      avgPrice40: 0,
      avgPurchase10: 0,
      avgPurchase40: 0,
      loading: false,
      storename: "",
      productId: "",
      obj40_source: {},
      obj10_source: {},
      obj10: {
        photoreview: 0,
        textreview: 0,
        zzim: 0,
        qa: 0,
        purchase: 0,
        exclude: "",
        w1: 0,
        w2: 0,
        w3: 0,
        w4: 0,
        w5: 0
      },
      obj40: {
        photoreview: 0,
        textreview: 0,
        zzim: 0,
        qa: 0,
        purchase: 0,
        exclude: "",
        w1: 0,
        w2: 0,
        w3: 0,
        w4: 0,
        w5: 0
      }
    };
  },
  computed: {
    SCAN_PRINT_MODE() {
      return SCAN_PRINT_MODE;
    },
    SCAN_NORMAL_MODE() {
      return SCAN_NORMAL_MODE;
    },
    getROAS10() {
      return parseInt(
        this.avgPurchase10 * this.avgPrice10 / this.getTotal10 * 100
      );
    },
    getROAS40() {
      return parseInt(
        this.avgPurchase40 * this.avgPrice40 / this.getTotal40 * 100
      );
    },
    getTotal10() {
      return (
        this.obj10.purchase * 5000 +
        this.obj10.textreview * 1000 +
        this.obj10.photoreview * 2000 +
        this.obj10.zzim * 1000 +
        this.obj10.qa * 1000
      );
    },
    getTotal40() {
      return (
        this.obj40.purchase * 5000 +
        this.obj40.textreview * 1000 +
        this.obj40.photoreview * 2000 +
        this.obj40.zzim * 1000 +
        this.obj40.qa * 1000
      );
    },
    getP1_10() {
      return this.obj10.purchase * 5000;
    },
    getP2_10() {
      return this.obj10.textreview * 1000;
    },
    getP3_10() {
      return this.obj10.photoreview * 2000;
    },
    getP4_10() {
      return this.obj10.zzim * 1000;
    },
    getP5_10() {
      return this.obj10.qa * 1000;
    },
    getP1_40() {
      return this.obj40.purchase * 5000;
    },
    getP2_40() {
      return this.obj40.textreview * 1000;
    },
    getP3_40() {
      return this.obj40.photoreview * 2000;
    },
    getP4_40() {
      return this.obj40.zzim * 1000;
    },
    getP5_40() {
      return this.obj40.qa * 1000;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  mounted() {
    if (this._mobile) {
      this.$router.push("/pages/notsupport").catch(() => {});
    } else {
      this.winWidth();
      if (this.pagemode == this.SCAN_PRINT_MODE) {
        this.keywordName = this.$route.query.keyword;
        this.invokeSearch();
      }
    }
    document.getElementById("fullroot").scrollTop = 0;
    fbq("track", "PageView");
  },
  methods: {
    onChangeMallType() {
      this.$vs.loading();
      this.obj40 = { ...this.obj40_source };
      this.obj10 = { ...this.obj10_source };

      if (this.mallType == ShoppingMall.COUPANG) {
        this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.82);
        this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.82);
        this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.82);
        this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.82);
        this.onConvert10(1);
        this.onConvert40(1);
      } else if (this.mallType == ShoppingMall.TMON) {
        this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.19);
        this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.19);
        this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.19);
        this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.19);
        this.onConvert10(0.4);
        this.onConvert40(0.4);
      } else if (this.mallType == ShoppingMall.WEMAFE) {
        this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.31);
        this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.31);
        this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.31);
        this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.31);
        this.onConvert10(0.6);
        this.onConvert40(0.6);
      } else if (this.mallType == ShoppingMall.KAKAO) {
        this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.5);
        this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.5);
        this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.5);
        this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.5);
        this.onConvert10(0.5);
        this.onConvert40(0.5);
      } else {
        this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 1);
        this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 1);
        this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 1);
        this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 1);
        this.onConvert10(1);
        this.onConvert40(1);
      }
      setTimeout(() => {
        this.$vs.loading.close();
      }, 500);
    },
    onConvert10(ratio) {
      this.obj10["purchase"] = parseInt(this.obj10["purchase"] * ratio);

      this.obj10["textreview"] = parseInt(this.obj10["purchase"] * this.ratio);
      this.obj10["photoreview"] =
        this.obj10["purchase"] - this.obj10["textreview"];
      this.obj10["zzim"] = parseInt(this.obj10["zzim"] * ratio);
      this.obj10["qa"] = parseInt(this.obj10["qa"] * ratio);

      var _w1 = 0;
      var _w2 = 0;
      var _w3 = 0;
      var _w4 = 0;
      var _w5 = 0;

      if (this.obj10["purchase"] >= 461) {
        _w1 = parseInt(this.obj10["purchase"] * 0.07);
        _w2 = parseInt(this.obj10["purchase"] * 0.11);
        _w3 = parseInt(this.obj10["purchase"] * 0.19);
        _w4 = parseInt(this.obj10["purchase"] * 0.26);
        _w5 =
          this.obj10["purchase"] - (_w1 + _w2 + _w3 + _w4) > 0
            ? this.obj10["purchase"] - (_w1 + _w2 + _w3 + _w4)
            : 0;
      } else if (
        this.obj10["purchase"] <= 460 &&
        this.obj10["purchase"] >= 221
      ) {
        _w1 = parseInt(this.obj10["purchase"] * 0.1);
        _w2 = parseInt(this.obj10["purchase"] * 0.18);
        _w3 = parseInt(this.obj10["purchase"] * 0.3);
        _w4 =
          this.obj10["purchase"] - (_w1 + _w2 + _w3) > 0
            ? this.obj10["purchase"] - (_w1 + _w2 + _w3)
            : 0;
      } else if (
        this.obj10["purchase"] <= 220 &&
        this.obj10["purchase"] >= 111
      ) {
        _w1 = parseInt(this.obj10["purchase"] * 0.15);
        _w2 = parseInt(this.obj10["purchase"] * 0.3);
        _w3 =
          this.obj10["purchase"] - (_w1 + _w2) > 0
            ? this.obj10["purchase"] - (_w1 + _w2)
            : 0;
      } else {
        _w1 = parseInt(this.obj10["purchase"] * 0.34);
        _w2 =
          this.obj10["purchase"] - _w1 > 0 ? this.obj10["purchase"] - _w1 : 0;
      }

      this.obj10["w1"] = _w1;
      this.obj10["w2"] = _w2;
      this.obj10["w3"] = _w3;
      this.obj10["w4"] = _w4;
      this.obj10["w5"] = _w5;
    },
    onConvert40(ratio) {
      this.obj40["purchase"] = parseInt(this.obj40["purchase"] * ratio);

      this.obj40["textreview"] = parseInt(this.obj40["purchase"] * this.ratio);
      this.obj40["photoreview"] =
        this.obj40["purchase"] - this.obj40["textreview"];

      this.obj40["zzim"] = parseInt(this.obj40["zzim"] * ratio);
      this.obj40["qa"] = parseInt(this.obj40["qa"] * ratio);

      var _w1 = 0;
      var _w2 = 0;
      var _w3 = 0;
      var _w4 = 0;
      var _w5 = 0;

      if (this.obj40["purchase"] >= 461) {
        _w1 = parseInt(this.obj40["purchase"] * 0.07);
        _w2 = parseInt(this.obj40["purchase"] * 0.11);
        _w3 = parseInt(this.obj40["purchase"] * 0.19);
        _w4 = parseInt(this.obj40["purchase"] * 0.26);
        _w5 =
          this.obj40["purchase"] - (_w1 + _w2 + _w3 + _w4) > 0
            ? this.obj40["purchase"] - (_w1 + _w2 + _w3 + _w4)
            : 0;
      } else if (
        this.obj40["purchase"] <= 460 &&
        this.obj40["purchase"] >= 221
      ) {
        _w1 = parseInt(this.obj40["purchase"] * 0.1);
        _w2 = parseInt(this.obj40["purchase"] * 0.18);
        _w3 = parseInt(this.obj40["purchase"] * 0.3);
        _w4 =
          this.obj40["purchase"] - (_w1 + _w2 + _w3) > 0
            ? this.obj40["purchase"] - (_w1 + _w2 + _w3)
            : 0;
      } else if (
        this.obj40["purchase"] <= 220 &&
        this.obj40["purchase"] >= 111
      ) {
        _w1 = parseInt(this.obj40["purchase"] * 0.15);
        _w2 = parseInt(this.obj40["purchase"] * 0.3);
        _w3 =
          this.obj40["purchase"] - (_w1 + _w2) > 0
            ? this.obj40["purchase"] - (_w1 + _w2)
            : 0;
      } else {
        _w1 = parseInt(this.obj40["purchase"] * 0.34);
        _w2 =
          this.obj40["purchase"] - _w1 > 0 ? this.obj40["purchase"] - _w1 : 0;
      }

      this.obj40["w1"] = _w1;
      this.obj40["w2"] = _w2;
      this.obj40["w3"] = _w3;
      this.obj40["w4"] = _w4;
      this.obj40["w5"] = _w5;
    },

    winWidth: function() {
      var self = this;
      setInterval(() => {
        var w = window.innerWidth;

        if (w < `1316`) {
          //1280 x 1024
          self.span1 = 8;
          self.span2 = 8;
          self.span3 = 24;
        } else {
          // 3840 x 2160
          self.span1 = 6;
          self.span2 = 4;
          self.span3 = 12;
        }
      }, 100);
    },
    download() {
      this.printme = false;
      setTimeout(() => {
        window.print();
      }, 500);
      setTimeout(() => {
        this.printme = true;
      }, 2000);
    },
    invokeSearch() {
      //DO NOT CHECK LICENSE

      if (this.keywordName) {
        this.ratio = random.int(55, 65) / 100;
        this.keywordName = replaceAll(this.keywordName, " ", "");
        this.displayKeyword = this.keywordName;
        var dummy = {
          photoreview: 0,
          textreview: 0,
          zzim: 0,
          qa: 0,
          purchase: 0,
          w1: 0,
          w2: 0,
          w3: 0,
          w4: 0,
          w5: 0
        };
        this.obj10 = dummy;
        this.obj40 = dummy;

        this.mode = 1;
        this.avgPrice10 = 0;
        this.avgPrice40 = 0;
        this.avgPurchase10 = 0;
        this.avgPurchase40 = 0;
        this.$vs.loading();
        this.onRun();
        this.onSaveNaverKeywordSnapshot(this.keywordName);
      }
    },
    onSaveNaverKeywordSnapshot(_keywordname) {
      var _key = encodeURIComponent(_keywordname);
      var db = firebase.firestore();
      var self = this;

      var sfDocRef = db.collection("storelink_naver_keyword").doc(_key);

      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            if (!sfDoc.exists) {
              db
                .collection("storelink_naver_keyword")
                .doc(_key)
                .set(
                  {
                    keyword: _keywordname,
                    eventtime: new Date(),
                    population: 1
                  },
                  {
                    merge: true
                  }
                )
                .then(function() {});
            } else {
              var newPopulation = sfDoc.data().population + 1;
              transaction.update(sfDocRef, {
                population: newPopulation,
                eventtime: new Date()
              });
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch(error => {
          console.log("Transaction failed: ", error);
        });
    },
    onGotoPrintPage() {
      let routeData = this.$router.resolve({
        name: "scanprint",
        query: {
          keyword: this.keywordName
        }
      });
      window.open(routeData.href, "_blank");
    },
    onRun() {
      var self = this;
      var __keyword = this.keywordName;
      this.$http
        .get(
          "https://apv14rpqj9.execute-api.ap-northeast-2.amazonaws.com/prod/onGetNaverState?keywordname=" +
            __keyword
        )
        .then(function(r) {
          self.onRun1(r.data.data.msmobile, r.data.data.mspc);
        });
    },
    onRun1(_msmobile, _mspc) {
      var self = this;
      this.storelist = [];
      var __keyword = this.keywordName;
      var _needqty10 = 0;
      var _totalday10 = 0;
      var _needqty40 = 0;
      var _totalday40 = 0;
      var obj10 = {};
      var obj40 = {};

      this.$http
        .post(
          "https://asia-east2-storelink-influencer.cloudfunctions.net/onAnalysisNew4",
          {
            keyword: self.keywordName
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Methods": "POST,HEAD,GET",
              "Access-Control-Allow-Headers":
                "Accept-Language, Content-Type, Accept, Content-Language",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Max-Age": 86400,
              "Cache-Control": "no-cache"
            }
          }
        )
        .then(function(r) {
          var _s1 = r.data.data.src.indexOf('<script id="__NEXT_DATA__"');
          var _e1 = r.data.data.src.indexOf("script>", _s1);
          var _test = r.data.data.src.substring(_s1, _e1 + 7);

          var _test2 = JSON.parse($($.parseHTML(_test))[0].innerHTML);

          var _totalproduct =
            _test2.props.pageProps.initialState.mainFilters[0].filterValues[0]
              .productCount;
          var _totalsearch =
            parseInt(replaceAll(_mspc.toString(), "<", "")) +
            parseInt(replaceAll(_msmobile.toString(), "<", ""));

          var _r_ = _test2.props.pageProps.initialState.products.list;

          var _data_ = [];
          var __tmp__ = [];

          Object.keys(_r_).forEach(function(k) {
            var _v = _r_[k];
            if (_v.item.imageUrl) {
              __tmp__.push(_v.item);
            }
          });
          __tmp__.forEach(item => {
            if (item.adId) {
              //skip
            } else {
              var _relevance_ = item.relevance ? item.relevance : "";
              var _similarity_ = item.similarity ? item.similarity : "";
              var _id_ = item.mallProductId
                ? item.mallProductId
                : item.mallPid ? item.mallPid : item.id;
              var _category_ =
                item.category1Name +
                " > " +
                item.category2Name +
                " > " +
                item.category3Name;
              var _title_ = item.productTitle ? item.productTitle : "";
              var _price_ = item.price ? item.price : 0;
              var _cumReviewCount_ = item.reviewCountSum
                ? item.reviewCountSum
                : 0;
              var _cumPurchaseCount_ = item.purchaseCnt ? item.purchaseCnt : 0;

              var _imgUrl_ = item.imageUrl ? item.imageUrl : "";
              var _hit_ = item.hit ? item.hit : 0;
              var _isGroup_ = "F";
              var _is7_ = "F";
              var _daysSaleScore7_ = item.daysSaleScore7
                ? item.daysSaleScore7
                : 0;
              var _newNormHit_ = item.newNormHit ? item.newNormHit : 0;
              var _newNormAbuseScore_ = item.newNormAbuseScore
                ? item.newNormAbuseScore
                : 0;
              var _mallId = item.mallName
                ? item.mallName
                : item.maker ? item.maker : "X";
              var _low_ = item.lowMallList ? item.lowMallList : [];
              if (_low_.length > 0) {
                _isGroup_ = "T";
              }
              if (item.dealName == "럭키투데이") {
                _is7_ = "7";
                _mallId = item.productName;
              } else {
                _mallId = _mallId;
              }
              var _ref_ = item.mallProductUrl ? item.mallProductUrl : "";

              var _opendate = "";
              var _gap = 1;

              if (item.openDate) {
                _opendate =
                  item.openDate.substr(0, 4) +
                  "/" +
                  item.openDate.substr(4, 2) +
                  "/" +
                  item.openDate.substr(6, 2);

                _gap = Math.round(
                  Math.abs(dateDiffInDays(new Date(_opendate)) / 30),
                  0
                );
                _gap = _gap == 0 ? 1 : _gap;
                _gap = _gap > 6 ? 6 : _gap;
              }

              var _t = {
                rank: item.rank,
                relevance: _relevance_,
                similarity: _similarity_,
                id: _id_,
                category: _category_,
                title: _title_,
                price: _price_,
                cumReviewCount: _cumReviewCount_,
                cumPurchaseCount: _cumPurchaseCount_,
                imgUrl: _imgUrl_,
                hit: _hit_,
                isGroup: _isGroup_,
                is7: _is7_,
                daysSaleScore7: _daysSaleScore7_,
                newNormHit: _newNormHit_,
                newNormAbuseScore: _newNormAbuseScore_,
                mallId: _mallId,
                gap: _gap,
                opendate: _opendate,
                ref: _ref_
              };
              _data_.push(_t);
            }
          });

          if (!isNaN(_totalproduct)) {
            // console.log(_totalsearch)
            // _totalproduct = Math.round(_totalproduct / 3);
            // console.log(_totalproduct);
            var sref2 = firebase.database().ref("analysis_matrix/");

            sref2.once("value", function(sdata2) {
              sdata2.forEach(function(sdatas2) {
                if (
                  sdatas2.val().s_l <= _totalsearch &&
                  sdatas2.val().s_u > _totalsearch &&
                  sdatas2.val().p_l <= _totalproduct &&
                  sdatas2.val().p_u > _totalproduct &&
                  sdatas2.val().type == 10
                ) {
                  _needqty10 = sdatas2.val().purchase;
                  _totalday10 = sdatas2.val().period;

                  obj10["textreview"] = sdatas2.val().text;
                  obj10["photoreview"] = sdatas2.val().photo;
                  obj10["zzim"] = sdatas2.val().zzim;
                  obj10["qa"] = sdatas2.val().qa;

                  obj10["exclude"] = "F";
                  obj10["w1"] = sdatas2.val().w1;
                  obj10["w2"] = sdatas2.val().w2;
                  obj10["w3"] = sdatas2.val().w3;
                  obj10["w4"] = sdatas2.val().w4;
                  obj10["w5"] = sdatas2.val().w5;
                }

                if (
                  sdatas2.val().s_l <= _totalsearch &&
                  sdatas2.val().s_u > _totalsearch &&
                  sdatas2.val().p_l <= _totalproduct &&
                  sdatas2.val().p_u > _totalproduct &&
                  sdatas2.val().type == 40
                ) {
                  _needqty40 = sdatas2.val().purchase;
                  _totalday40 = sdatas2.val().period;

                  obj40["textreview"] = sdatas2.val().text;
                  obj40["photoreview"] = sdatas2.val().photo;
                  obj40["zzim"] = sdatas2.val().zzim;
                  obj40["qa"] = sdatas2.val().qa;

                  obj40["exclude"] = "F";
                  obj40["w1"] = sdatas2.val().w1;
                  obj40["w2"] = sdatas2.val().w2;
                  obj40["w3"] = sdatas2.val().w3;
                  obj40["w4"] = sdatas2.val().w4;
                  obj40["w5"] = sdatas2.val().w5;
                }
              });

              var _data = {
                needqty10: _needqty10,
                totalday10: _totalday10,
                needqty40: _needqty40,
                totalday40: _totalday40,
                totalproduct: _totalproduct,
                totalsearch: _totalsearch,
                mspc: _mspc,
                msmobile: _msmobile,
                obj10: obj10,
                obj40: obj40,
                page: _data_
              };

              self.obj10 = _data.obj10;
              self.obj40 = _data.obj40;
              self.obj10["purchase"] = _data.needqty10 ? _data.needqty10 : 0;
              self.obj40["purchase"] = _data.needqty40 ? _data.needqty40 : 0;

              if (_data.page) {
                var _totalPurchsae10 = 0;
                var _totalPrice10 = 0;

                var _baseTop10Index = 0;
                var _base1PageIndex = 0;

                var _totalPurchsae40 = 0;
                var _totalPrice40 = 0;

                _data.page.forEach(item => {
                  if (item.imgUrl) {
                    if (item.cumPurchaseCount > 0) {
                      _base1PageIndex++;

                      var _cumPurchaseCount = item.cumPurchaseCount / item.gap;

                      _totalPurchsae40 += _cumPurchaseCount;
                      _totalPrice40 += parseInt(item.price);

                      self.storelist.push({
                        title: item.title,
                        rank: item.rank,
                        purchase: _cumPurchaseCount,
                        isGroup: "F",
                        price: parseInt(item.price),
                        label:
                          item.rank +
                          "순위 제품 1개월 평균 예측치 [" +
                          item.title +
                          "]"
                      });

                      if (item.rank < 11) {
                        _baseTop10Index++;
                        _totalPurchsae10 += _cumPurchaseCount;
                        _totalPrice10 += parseInt(item.price);
                      }
                    } else {
                      self.storelist.push({
                        title: item.title,
                        rank: item.rank,
                        isGroup: item.isGroup,
                        purchase: 0,
                        price: parseInt(item.price),
                        label:
                          item.rank +
                          "순위 제품 1개월 평균 예측치 [" +
                          item.title +
                          "]"
                      });
                    }
                  }
                });

                var __dummy__ = self.storelist;

                self.storelist = __dummy__.sort(function(a, b) {
                  // desc
                  return a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0;
                });
                if (_totalPrice40 > 0) {
                  self.avgPrice40 = parseInt(_totalPrice40 / _base1PageIndex);
                }

                if (_totalPurchsae40 > 0) {
                  self.avgPurchase40 = parseInt(
                    _totalPurchsae40 / _base1PageIndex
                  );

                  if (self.avgPurchase40 > self.obj40["purchase"] * 1.5) {
                    self.obj40["purchase"] = parseInt(
                      self.obj40["purchase"] + self.avgPurchase40 * 0.3
                    );
                    self.obj40["textreview"] = parseInt(
                      self.obj40["textreview"] + self.avgPurchase40 * 0.1
                    );
                    self.obj40["photoreview"] = parseInt(
                      self.obj40["photoreview"] + self.avgPurchase40 * 0.1
                    );
                    self.obj40["zzim"] = parseInt(
                      self.obj40["zzim"] + self.avgPurchase40 * 0.15
                    );
                    self.obj40["qa"] = parseInt(
                      self.obj40["qa"] + self.avgPurchase40 * 0.02
                    );
                  }
                  self.obj40["avgPurchase"] = self.avgPurchase40;
                  self.obj40["avgPrice"] = self.avgPrice40;
                  self.obj40_source = self.obj40;
                }

                if (_totalPrice10 > 0) {
                  self.avgPrice10 = parseInt(_totalPrice10 / _baseTop10Index);
                }

                if (_totalPurchsae10 > 0) {
                  self.avgPurchase10 = parseInt(
                    _totalPurchsae10 / _baseTop10Index
                  );

                  if (self.avgPurchase10 > self.obj10["purchase"] * 1.5) {
                    self.obj10["purchase"] = parseInt(
                      self.obj10["purchase"] + self.avgPurchase10 * 0.3
                    );
                    self.obj10["textreview"] = parseInt(
                      self.obj10["textreview"] + self.avgPurchase10 * 0.1
                    );
                    self.obj10["photoreview"] = parseInt(
                      self.obj10["photoreview"] + self.avgPurchase10 * 0.1
                    );
                    self.obj10["zzim"] = parseInt(
                      self.obj10["zzim"] + self.avgPurchase10 * 0.15
                    );
                    self.obj10["qa"] = parseInt(
                      self.obj10["qa"] + self.avgPurchase10 * 0.02
                    );
                  }

                  self.obj10["avgPurchase"] = self.avgPurchase10;
                  self.obj10["avgPrice"] = self.avgPrice10;
                  self.obj10_source = self.obj10;
                }
                self.onChangeMallType();
                self.$vs.loading.close();
              } else {
                self.$vs.loading.close();
              }
            });
          } else {
            self.$vs.loading.close();
          }
        });
    }
  }
};
</script>

<style>
.s-scan-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.88px;
  text-align: center;
  color: #474747;
}

.scan .print {
  margin-top: -40px;
}

.s-scan-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-scan-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.37px;
  color: #7d7d7d;
}

.s-scan-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.65px;
  color: #606060;
}

.s-scan-font4.left {
  padding-left: 12px;
}

.s-scan-font5 {
  font-family: "Muli";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #0264fb;
}

.s-scan-font6 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-scan-font7 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  padding-top: 20px;
  height: 70px;
}

.s-scan-font8 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
  width: 70px;
  padding-top: 2px;
}

.s-scan-font9 {
  font-family: "Muli";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-scan-font10 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
  padding-left: 3px;
  padding-top: 3px;
}

.s-scan-dash2 {
  padding-top: 7px;
  padding-right: 7px;
  color: #d8d8d8;
}

.s-scan-btn1 button {
  background-color: #0264fb !important;
  border-color: #0264fb !important;
  height: 60px;
  width: 153px;
}

.s-scan-btn1 input {
  height: 60px;
}

.s-scan-card1 {
  /* height: 260px; */
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-scan-card3 {
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-scan-card3.right {
  margin-right: 12px;
}

.s-scan-card3.left {
  margin-left: 12px;
}

.s-scan-card4 {
  height: 248px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 20px;
  width: 98%;
}

.s-scan-card5 {
  height: 108px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
}

.s-scan-card2 {
  height: 100%;
  /* height: 260px; */
  padding: 20px;
}

.s-scan-card3-1 {
  height: 100%;
  padding: 20px;
}

.s-scan-card4-1 {
  height: 248px;
  padding: 20px;
}

/* .s-scan-card2.right,
.s-scan-card3-1.right {
  border-right: 1px solid #dbdbdb;
} */

.s-scan-card2 .f0,
.s-scan-card3-1 .f0,
.s-scan-card2 .f5 {
  display: flex;
}

.s-scan-card2 .f0 > img,
.s-scan-card3-1 .f0 > img,
.s-scan-card2 .f5 > img {
  width: 24px;
  height: 24px;
}

.s-scan-card2 .f5 {
  margin-top: 19px;
}

.s-scan-card2 .f0 > span,
.s-scan-card3-1 .f0 > span,
.s-scan-card3-1 .f1 > span,
.s-scan-card2 .f5 > span {
  padding-left: 5px;
  padding-top: 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
}

.s-scan-card2 .f1 {
  font-family: muli, sans-serif;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.13px;
  color: #000000;
  margin-top: 28px;
}

.s-scan-card2 .f1 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #000000;
}

.s-scan-card2 .f2 {
  margin-top: 8px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #000000;
}

.s-scan-card2 .f3 {
  margin-top: 20px;
  font-family: muli, sans-serif;
  font-size: 32px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2.25px;
  color: #000000;
}

.s-scan-card2 .f3 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
}

.s-scan-card2 .f4 {
  margin-top: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -1.13px;
  color: #000000;
}

.s-scan-card2 .f6 {
  margin-top: 10px;
  font-family: muli, sans-serif;
  font-size: 32px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2.25px;
  color: #0264fb;
}

.s-scan-card2 .f6 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #0264fb;
}

.s-scan-card3-1 .f1 {
  margin-top: 20px;
  font-family: muli, sans-serif;
  font-size: 26px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2.25px;
  color: #000000;
}

.s-scan-card3-1 .f1 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
}

.s-scan-card3-1 .f2 {
  margin-top: 20px;
  font-family: muli, sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2.25px;
  color: #0264fb;
}

.s-scan-card3-1 .f2 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #0264fb;
}

.empty {
  padding-top: 30px;
  padding-bottom: 30px;
}

.empty2 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.empty > img,
.empty2 > img {
  width: 33px;
  height: 42px;
}

.empty > span,
.empty2 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #bfbfbf;
}


.s-scan-tag {
  height: 24px;
  margin: 1px 0 0;
  border-radius: 3px;
  background-color: #0264fb;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.37px;
  color: #ffffff;
}

@media print {
  @page {
    size: A4 landscape;
    margin-left: 0cm;
    margin-right: 0cm;
  }
}
</style>
